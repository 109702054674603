body {
	background-color: #efefef !important;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.mapContainer {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}

.leaflet-container {
	/* height: calc(100vh - 48px);
	width: 100vw; */
	height: 100%;
	width: 100%;
}

.split-container {
	height: calc(100vh - 48px);
	width: 100vw;
}

.split-parent {
	height: 100%;
	width: 100%;
}

.gutter {
	background-color: #eee;
	background-repeat: no-repeat;
	background-position: 50%;
}

.gutter.gutter-horizontal {
	float: left;
	cursor: ew-resize;
	height: 100%;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}

.split-parent.split-horizontal .split-item {
	float: left;
	height: 100%;
}

.map-sidebar {
	padding: 10px;
}

.p10 {
	padding: 10px;
}

.pl10 {
	padding-left: 10px;
}

.rl10 {
	padding-right: 10px;
}

.pt10 {
	padding-top: 10px;
}

.mt15 {
	margin-top: 15px;
}

.mt5 {
	margin-top: 5px;
}

.mb5 {
	margin-bottom: 5px;
}

.mt10 {
	margin-top: 10px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb20 {
	margin-bottom: 20px;
}

.pr0 {
	padding-right: 0;
}

.w100p {
	width: 100%;
}

.h100p {
	height: 100%;
}

.header-menu-item {
	padding: 0px 5px;
	color: #666666;
}

.header-menu-item a:hover {
	color: #ff6400;
}

.paper-container {
	padding: 5px;
}

.stretch {
	height: 100%;
	width: 100%;
}

.scroll-v {
	overflow: hidden;
	overflow-y: auto;
}

/* nav menu */

.nav-menu-item-label {
	color: #767676;
	margin-left: 3px;
}

.nav-menu-item {
	cursor: pointer;
	margin-right: 5px !important;
}

/* colors */

.error-bg {
	background-color: #f44336;
}

.error-text {
	color: #f44336;
}

.ReactVirtualized__Table__Grid {
	outline: 0;
}

/* Admin sales form filters compaction css */

.admin-sales-sidebar .MuiFormLabel-root {
	font-size: 0.8rem;
}

.admin-sales-sidebar .MuiInputBase-root {
	font-size: 0.8rem;
}

.admin-sales-sidebar .MuiInputBase-input {
	padding-top: 0px !important;
}

.admin-sales-sidebar .prospective-customer .MuiInputBase-input {
	padding: 8.5px 14px !important;
}

.map-search-input .MuiAutocomplete-input {
	padding: 0 !important;
}

.admin-sales .MuiAccordion-root {
	margin: 16px 0;
}

.admin-sales .MuiAccordionDetails-root {
	padding: 0 0 16px;
}

.MuiAccordionSummary-root {
	min-height: 40px !important;
	padding: 0 5px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
	min-height: 20px !important;
	padding: 5px 5px !important;
}

.MuiAccordionSummary-content {
	margin: 0 !important;
}

.MuiAccordionSummary-root .MuiIconButton-root {
	padding: 0 0 0 12px !important;
}

.MuiAccordionSummary-root .MuiIconButton-edgeEnd {
	margin: 0 !important;
}

.MuiRadio-root.MuiRadio-colorPrimary {
	padding: 2px;
}

.map-setting .MuiFormLabel-root {
	font-size: 0.85rem;
}

.sa1-report .MuiChip-labelSmall {
	padding-left: 5px;
	padding-right: 5px;
	font-size: 10px;
	font-weight: 500;
}

.sa1-report .filter-item {
	margin-right: 20px;
	margin-bottom: 15px;
}

/* Empty Screen */

.emptyScreenView {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
}

.admin-sales .MuiAccordion-root {
	/* margin: 16px 0; */
}

.admin-sales .MuiAccordionDetails-root {
	/* padding: 0 0 16px; */
	padding: 0;
}

.MuiAccordionSummary-root {
	min-height: 40px !important;
	padding: 0 5px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
	min-height: 20px !important;
	padding: 5px 5px !important;
}

.MuiAccordionSummary-content {
	margin: 0 !important;
}

.MuiAccordionSummary-root .MuiIconButton-root {
	padding: 0 0 0 12px !important;
}

.MuiAccordionSummary-root .MuiIconButton-edgeEnd {
	margin: 0 !important;
}

/* .admin-sales .paper-container {
	padding: 0;
} */

.stateFilterOptions {
	font-size: 0.9rem !important;
	padding: 5px 12px 0 12px !important;
}

.stateFilter {
	font-size: 0.9rem !important;
}

/* .MuiAutocomplete-option {
	font-size: 0.90rem !important;
} */

.MuiButton-root.MuiButton-outlined {
	padding: 2px 5px;
	font-size: 0.775rem;
}

.selectedAreaStatus {
	font-size: 0.7rem;
}

/* .admin-sales label+.MuiInput-formControl {
	margin-top: 0 !important;
} */

.admin-sales-sidebar .MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(14px, -6px) scale(0.75);
}

.admin-sales-sidebar .MuiInputLabel-outlined {
	transform: translate(14px, 12px) scale(1);
}

.admin-sales-sidebar .MuiSelect-root.MuiOutlinedInput-input {
	padding: 10.5px 14px !important;
}

.mr20 {
	margin-right: 20px !important;
}

.sa1-report .MuiSelect-selectMenu {
	font-size: 0.75rem;
}

.date-filter .MuiInputAdornment-root .MuiIconButton-root {
	padding: 0;
}

.map-setting .MuiFormControl-root {
	margin-bottom: 10px;
}

.map-setting .MuiFormControlLabel-label {
	color: rgba(0, 0, 0, 0.54);
	font-size: 0.9rem;
}

/* Full Area loader  */

.LoadingContainer {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
}

.LoadingDialogContainer {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.8);
}

.spinner {
	-webkit-animation: rotate 2s linear infinite;
	animation: rotate 2s linear infinite;
	width: 50px;
	height: 50px;
}

.LoadingText {
	color: #222 !important;
	text-align: center;
	margin-top: 8px !important;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.spinner .path {
	stroke: #eea849;
	stroke-linecap: round;
	-webkit-animation: dash 1.5s ease-in-out infinite;
	animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}

/*  */

.menu-list a {
	text-decoration: none;
	display: flex;
	align-items: center;
	color: rgb(118, 118, 118);
}

.cdar-list p {
	font-size: 0.695rem;
}

.admin-sales-list-area {
	flex: auto !important;
}

.admin-sale .ReactVirtualized__Table__headerColumn {
	flex: auto !important;
}

.Modal-title {
	color: #FF6400;
	font-size: 1.05rem !important;
}

.submit-btn {
	background: #FF6400 !important;
	border: 1px solid #FF6400 !important;
	color: white !important;
	display: block !important;
	text-align: center !important;
	cursor: pointer;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	text-decoration: none;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 400 !important;
	text-transform: uppercase !important;
	border-radius: 3px !important;
	font-family: 'Open Sans', sans-serif !important;
	font-size: 12px !important;
	line-height: 10px !important;
	padding: 8px 25px !important;
}

.cancel-btn {
	display: block !important;
	text-align: center;
	cursor: pointer;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	text-decoration: none;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 400 !important;
	text-transform: uppercase !important;
	border-radius: 3px !important;
	font-family: 'Open Sans' !important;
	font-size: 12px !important;
	line-height: 10px !important;
	padding: 8px 25px !important;
	color: #767676 !important;
	background: #fff !important;
	border: 1px solid #E5E5E5 !important;
	box-shadow: none !important;
}

.modal-label {
	font-size: 12px !important;
	color: #404040 !important;
	line-height: 17px !important;
	text-transform: none;
	margin-bottom: 5px;
	margin-top: 5px;
}

.autoComplete .MuiInputBase-root {
	background: white !important;
	width: 100%;
	/* padding: 11px !important; */
	line-height: 18px !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: #404040 !important;
	border: 1px solid #EFEFEF !important;
	/* display: block !important; */
	border-radius: 3px !important;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 42px !important;
	/* margin-bottom: 15px; */
}

.autoComplete p {
	margin-left: 0 !important;
}

.autoComplete input {
	width: auto !important;
	/* padding: 0 !important; */
}

.modal-textfield {
	padding: 10px 18px !important;
	margin-top: 0 !important;
	font-size: 13px !important;
	font-weight: 400 !important;
}

.modal-textfield input {
	padding: 0 !important;
}

.error-content {
	padding: 0 !important;
}

.material-table-header {
	background-color: #FAFAFA !important;
	border: 1px solid #E5E5E5;
	padding: 4px 24px 6px 16px !important;
}

.material-table-header span {
	color: #404040 !important
}

.material-table-body .radio-check.Mui-checked span {
	color: #FF6400 !important
}

.material-table-body span {
	color: #404040 !important
}

.table-header-row th {
	/* border: none !important; */
	color: #404040 !important;
	font-weight: 600 !important;
	padding-top: 10px;
	padding-bottom: 10px;
}

.border-left {
	border-left: 1px solid #ccc !important;
}

.border-right-none {
	border-right: none !important;
}

.border-left-none {
	border-left: none !important;
}

.border-top {
	border-top: 1px solid #ccc !important;
}

.border-right {
	border-right: 1px solid #ccc !important;
}

::placeholder {
	color: #404040 !important;
	opacity: 1;
}

.empty-record-cell {
	padding: 20px !important;
	color: #404040;
}

.material-table-body tr {
	height: 38px;
}

.concierge-map-section {
	width: 40%;
}

.concierge-table-section {
	margin-top: 10px;
	height: calc(100vh - 202px);
}

.boxdraw {
	background: rgba(56, 135, 190, 0.1);
	border: 2px solid #3887be;
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
}

.ellipsis-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 62px;
	padding-right: 10px;
}